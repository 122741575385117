import React, { useEffect, forwardRef, useState } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import moment from "moment";
import "moment/locale/es";

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'Folio', label: 'Folio', minWidth: 100 },
    { id: 'Nombre', label: 'Nombre', minWidth: 100 },
    { id: 'Celular', label: 'Celular', minWidth: 100 },
    { id: 'Correo', label: 'Correo', minWidth: 100 },
    { id: 'NombreCapturista', label: 'Capturista', minWidth: 100 }
];

function Captura(){
    const [capturas, setCapturas] = useState([]);
    const [estatus, setEstatus] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogError, setOpenDialogError] = useState(false);
    const [openDialogEnvio, setOpenDialogEnvio] = useState(false);

    const [IdCaptura, setIdCaptura] = useState("");
    const [Folio, setFolio] = useState("");
    const [TipoBase, setTipoBase] = useState("");
    const [FechaCaptura, setFechaCaptura] = useState("");
    const [FechaSanitizacion, setFechaSanitizacion] = useState("");

    const [Comentario, setComentario] = useState("");
    const [Agendado, setAgendado] = useState(false);
    const [IdEstatus, setIdEstatus] = useState("1");

    const [Nombre, setNombre] = useState("");
    const [Correo, setCorreo] = useState("");
    const [Celular, setCelular] = useState("");
    const [TelefonoFijo, setTelefonoFijo] = useState("");
    const [TelefonoTrabajo, setTelefonoTrabajo] = useState("");

    const [Cuenta, setCuenta] = useState("");
    const [ProductoUP, setProductoUP] = useState("");
    const [Costo, setCosto] = useState("");
    const [BancoEmisor, setBancoEmisor] = useState("");
    const [CondicionTDCAdicional, setCondicionTDCAdicional] = useState("");
    const [SegRevolvencia, setSegRevolvencia] = useState("");
    const [Saldo, setSaldo] = useState("");
    const [Propension, setPropension] = useState("");
    const [Antiguedad, setAntiguedad] = useState("");
    const [LimiteCredito, setLimiteCredito] = useState("");
    const [Incremento, setIncremento] = useState("");
    const [Adicional, setAdicional] = useState(false);
    const [MSI, setMSI] = useState("");

    const [Envio, setEnvio] = useState(false);
    const [IdEnvio, setIdEnvio] = useState('');
    const [Validar, setValidar] = useState(false);
    const [TextoEnvio, setTextoEnvio] = useState('');

    const [tipo, setTipo] = useState('referencia');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [Token, setToken] = useState('');
    const [TokenOpen, setTokenOpen] = useState(false);
    const [Intentos, setIntentos] = useState(0);

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userPortafolios")).IdUsuario;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const selectRow = (e, row) => {
        e.stopPropagation(); 
        e.preventDefault();

        let elemento = capturas.map(element => {
            if(element.IdCaptura === row.IdCaptura)
            {
                element.select = true;
                return element;
            }
            else
            {
                element.select = false;
                return element;
            }
        });

        setCapturas(elemento);
        limpiaValores();

        let cap = elemento.find(item => item.IdCaptura === row.IdCaptura);

        let now = moment(Date.now());
        now.set({h: 0, m: 0, s: 0});
        let Sanitizacion = moment(cap.FechaSanitizacion, "DD-MM-YYYY").set({hour: 23, minute: 59, second: 59, millisecond: 0}).toDate();

        if(moment(now.toDate()).isAfter(moment(Sanitizacion))){
            setOpenDialogError(true);
            setFolio(cap.Folio);
        } else {
            setIdCaptura(cap.IdCaptura)
            setFolio(cap.Folio);
            setTipoBase(cap.TipoBase);
            setFechaCaptura(cap.FechaCaptura);
            setFechaSanitizacion(cap.FechaSanitizacion);

            setComentario(cap.Comentario);
            setAgendado(cap.Agendado);
            setIdEstatus(cap.IdEstatus);

            setNombre(cap.Nombre);
            setCorreo(cap.Correo);
            setCelular(cap.Celular);
            setTelefonoFijo(cap.TelefonoFijo);
            setTelefonoTrabajo(cap.TelefonoTrabajo);

            setCuenta(cap.Cuenta);
            setProductoUP(cap.ProductoUP);
            setCosto(cap.Costo);
            setBancoEmisor(cap.BancoEmisor);
            setCondicionTDCAdicional(cap.CondicionTDCAdicional);
            setSegRevolvencia(cap.SegRevolvencia);
            setSaldo(cap.Saldo);
            setPropension(cap.Propension);
            setAntiguedad(cap.Antiguedad);
            setLimiteCredito(cap.LimiteCredito);
            setIncremento(cap.Incremento);
            setAdicional(cap.Adicional);
            setMSI(cap.MSI);
        }
    };

    const tipoNumero = (valor) => {
        let num = 0;

        switch(valor)
        {
            case "referencia":
                num = 1;
                break;
            case "telefono":
                num = 2;
                break
            case "agendada":
                num = 3;
                break
        }

        return num;
    };

    const buscar = (event) => {
        event.preventDefault();
        setLoad(true);

        limpiaValores();

        let busqueda = document.getElementById("busqueda").value;

        if(busqueda !== "" || tipo === "agendada"){
            Login()
            .then(result => {
                if(result !== undefined){
                    let params = {
                        Indicador: busqueda,
                        Tipo: tipoNumero(tipo),
                        Capturista: getUser()
                    };
    
                    ObtieneCapturasCapturistas(result.token, params)
                    .then(result => {
                        let capturas = result.capturas.map((element) => {
                            element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaSanitizacion = element.FechaSanitizacion === "" ? "" : moment(moment(element.FechaSanitizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            return element;
                        });
                        setCapturas(capturas);
                        setLoad(false);
    
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes agregar un elemento a buscar", "error");
            setLoad(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const DialogClose = () => {
        setOpenDialog(false);
        setTimeout(function(){ window.location.reload(false); }, 1000);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ActualizaCaptura = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ActualizaCaptura",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneCapturasCapturistas = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ObtieneCapturasCapturistas",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstatus = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estatus/ObtieneEstatus",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const EnviaMensaje = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Mensajes/EnviaMensaje",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const RespuestaMensaje = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Mensajes/RespuestaMensaje",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const GeneraToken = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Mensajes/GeneraToken",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ValidaToken = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Mensajes/ValidaToken",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const OnClickEnviaMensaje = () => {
        let inte = Intentos + 1;
        setIntentos(inte);

        if(IdCaptura !== "" && inte < 3){
            setLoad(true);

            let datos = {
                phone: Celular,
                id: IdCaptura.toString()
            };

            Login()
            .then(result => {
                EnviaMensaje(result.token, datos)
                .then(result => {
                    if(!result.result.error){
                        sendSnackBar("Envío exitoso", "success");
                        setEnvio(true);
                        setIdEnvio(result.result.description.id);
                    } else {
                        sendSnackBar(result.result.description, "error");
                    }
                    setLoad(false);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            setTextoEnvio("Debes seleccionar un cliente o excediste los 2 intentos");
            setOpenDialogEnvio(true);
        }
    };

    const OnClickRespuestaMensaje = () => {
        if(IdCaptura !== ""){
            setLoad(true);

            let datos = {
                token: IdEnvio.toString()
            };

            Login()
            .then(result => {
                let token = result.token;
                RespuestaMensaje(token, datos)
                .then(result => {
                    if(!result.result.error){
                        if(result.result.description.answers.length > 0){
                            let msg = result.result.description.answers[0].msg;
                            let mensajes = msg.split(' ');

                            let correcto = 0;

                            for (var i = 0; i < mensajes.length; i++) {
                                if(mensajes[i].toUpperCase().includes("SI") ||
                                   mensajes[i].toUpperCase().includes("OK") ||
                                   mensajes[i].toUpperCase().includes("ACUERDO")){
                                    correcto++;
                                }
                            }

                            if(correcto > 0){
                                let info = {
                                    TokenAdmin: false,
                                    IdCaptura,
                                    RespuestaMensaje: msg
                                };
    
                                GeneraToken(token, info)
                                .then(result => {
                                    sendSnackBar("Token generado exitosamente, respuesta del cliente: " + msg, "success");
                                    setValidar(true);
                                    setLoad(false);
                                }).catch(error => sendSnackBar("Error de conexión", "error"));
                            } else {
                                sendSnackBar("Respuesta negativa del cliente: " + msg, "error");
                                setLoad(false);
                            }
                            
                        } else {
                            sendSnackBar("El cliente no ha dado respuesta", "error");
                            setLoad(false);
                        }
                    } else {
                        sendSnackBar(result.result.description, "error");
                        setLoad(false);
                    }
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            setTextoEnvio("Debes seleccionar un cliente");
            setOpenDialogEnvio(true);
        }
    };

    const SolicitarToken = () => {
        if(Envio){
            setTokenOpen(true);
        }  else {
            setTextoEnvio("Debe enviar el mensaje al cliente");
            setOpenDialogEnvio(true);
        }
    };

    const ValidaDatos = () => {
        let datos = { IdCaptura, Cuenta: parseInt(Cuenta), TelefonoFijo, TelefonoTrabajo, SegRevolvencia, 
            Antiguedad: parseInt(Antiguedad), Adicional, Comentario, MSI, Agendado, IdEstatus: parseInt(IdEstatus), 
            FechaCapturista: moment(Date.now()).toDate(), Capturista: getUser()};
        
        return datos;
    }

    const EnviaDatos = () => {
        if((Envio && Validar) || IdEstatus == 2 || IdEstatus == 3 || Agendado){
            try{
                setLoad(true);
                
                let datos = ValidaDatos();
                
                if(IdCaptura !== ""){
                    Login()
                    .then(result => {
                        if(result !== undefined){
                            ActualizaCaptura(datos, result.token)
                            .then(result => {
                                if(result.success){
                                    sendSnackBar("Registro exitoso", "success");
                                    setOpenSnackbar(true);
                                    setOpenDialog(true);
                                } else {
                                    sendSnackBar("Error de conexión", "error");
                                }
                
                                setLoad(false);
                            }).catch(error => sendSnackBar("Error de conexión", "error"));
                        } else {
                            sendSnackBar("Error de conexión", "error");
                            setLoad(false);
                        }
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Todos los valores son obligatorios", "error");
                    setLoad(false);
                }
            } catch(error){
                sendSnackBar("Error de conexión", "error");
                setLoad(false);
            }
        } else if(Envio && !Validar){
            setTextoEnvio("Debe validar el envío");
            setOpenDialogEnvio(true);
        } else if(!Envio && !Validar){
            setTextoEnvio("Debe enviar el mensaje al cliente");
            setOpenDialogEnvio(true);
        }
    };

    const limpiaValores = () => {
        setIdCaptura("")
        setFolio("");
        setTipoBase("");
        setFechaCaptura("")
        setFechaSanitizacion("");

        setComentario("");
        setAgendado(false);
        setIdEstatus("1");

        setNombre("");
        setCorreo("");
        setCelular("");
        setTelefonoFijo("");
        setTelefonoTrabajo("");

        setCuenta("");
        setProductoUP("");
        setCosto("");
        setBancoEmisor("");
        setCondicionTDCAdicional("");
        setSegRevolvencia("");
        setSaldo("");
        setPropension("");
        setAntiguedad("");
        setLimiteCredito("");
        setIncremento("");
        setAdicional(false);
        setMSI("");
    };

    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userPortafolios')).IdUsuario;
    };

    const ValidarToken = () => {
        setLoad(true);

        let datos = {
            Token,
            IdCaptura
        };

        Login()
        .then(result => {
            ValidaToken(result.token, datos)
            .then(result => {
                if(result.result){
                    sendSnackBar("Validación exitosa, ya puede guardar la captura", "success");
                    setTokenOpen(false);
                    setValidar(true);
                    setToken('');
                } else {
                    sendSnackBar("Token incorrecto", "error");
                }
                setLoad(false);
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    };

    useEffect(() => {
        Login()
        .then(result => {
            if(result !== undefined){
                let token = result.token;

                UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
                .then(result => {
                    if(result.activo === false){
                        localStorage.removeItem("userPortafolios");
                        return <Redirect to='/admin'/>;
                    }
                });

                ObtieneEstatus(token)
                .then(result => {
                    setEstatus(result.estatus);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Error de conexión", "error");
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6} xl={6}>
                    <Paper
                        style={{ marginBottom: "30px" }}
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            id="busqueda"
                        />
                        <IconButton onClick={buscar} sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                            <FormControlLabel
                                checked={tipo === 'referencia'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="referencia" 
                                control={<Radio />} 
                                label="Referencia" />
                            <FormControlLabel
                                checked={tipo === 'telefono'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="telefono" 
                                control={<Radio />} 
                                label="Teléfono" />
                            <FormControlLabel
                                checked={tipo === 'agendada'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="agendada" 
                                control={<Radio />} 
                                label="Agendada" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { capturas.length > 0 && 
                    (
                        <Grid item xs={12} sm={12} xl={12} style={{ marginBottom: "30px" }}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {capturas.map((row) => {
                                            return (
                                                <TableRow onClick={(e) => selectRow(e, row)} selected={row.select} hover={true} tabIndex={-1} key={capturas.IdCaptura}>
                                                    {columns.map((column) => {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                { column.id === "Nombres" ? 
                                                                    row["Nombres"]
                                                                    : 
                                                                    row[column.id]
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={capturas.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Indices
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={IdCaptura} disabled={true} label="Referencia" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Folio} disabled={true} label="Folio" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={TipoBase} disabled={true} label="Tipo de base" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={FechaCaptura} disabled={true} label="Fecha de alta" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={FechaSanitizacion} disabled={true} label="Fecha vencimiento" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Pendientes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setComentario(event.target.value)} value={Comentario} label="Comentarios" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 500 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={Agendado} onChange={(event) => setAgendado(event.target.checked)} />} label="Agendada" style={{ color: "black" }} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estatus*"
                            value={IdEstatus} 
                            onChange={(event) => setIdEstatus(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {estatus.map((option) => (
                                <MenuItem key={option.IdEstatus} value={option.IdEstatus}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Nombre} disabled={true} label="Nombre" variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Correo} disabled={true} label="Correo" type="email" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Celular} disabled={true} label="Celular" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setTelefonoFijo(event.target.value)} value={TelefonoFijo} label="Teléfono fijo" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setTelefonoTrabajo(event.target.value)} value={TelefonoTrabajo} label="Teléfono de trabajo" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Referencias
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCuenta(event.target.value)} value={Cuenta} label="Cuenta" helperText="Solo números" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Producto" value={ProductoUP} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Costo" disabled={true} value={Costo} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Banco Emisor" disabled={true} value={BancoEmisor} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="TDC Adicional" onChange={(event) => setCondicionTDCAdicional(event.target.value)} value={CondicionTDCAdicional} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Revolvencia" value={SegRevolvencia} onChange={(event) => setSegRevolvencia(event.target.value)} variant="outlined" inputProps={{ maxlength: 100 }} style={{ width: "100%" }}></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Saldo" disabled={true} value={Saldo} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Propension" disabled={true} value={Propension} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Antiguedad" value={Antiguedad} onChange={(event) => setAntiguedad(event.target.value)} variant="outlined" helperText="Solo números" inputProps={{ maxlength: 45 }} style={{ width: "100%" }}></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Limite Credito" disabled={true} value={LimiteCredito} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Incremento" disabled={true} value={Incremento} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="MSI" value={MSI} onChange={(event) => setMSI(event.target.value)} variant="outlined" inputProps={{ maxlength: 45 }} style={{ width: "100%" }}></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={Adicional} />} onChange={(event) => setAdicional(event.target.checked)} label="Adicional" style={{ color: "black" }} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Card>
            <Grid container style={{alignItems: "flex-end"}}>
                {
                    IdEstatus == 1 ? (
                        <>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Button
                                color="info"
                                variant="contained"
                                onClick={OnClickEnviaMensaje}
                                style={{ float: "right" }}
                                size="large"
                                >
                                    {"Enviar mensaje"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Button
                                color="info"
                                variant="contained"
                                onClick={OnClickRespuestaMensaje}
                                style={{ float: "right" }}
                                size="large"
                                >
                                    {"Validar mensaje"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Button
                                color="info"
                                variant="contained"
                                onClick={SolicitarToken}
                                size="large"
                                style={{ float: "right" , color: "transparent", backgroundColor: "transparent", boxShadow: "none"}}
                                >
                                    {"Solicitar token"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                            <Button
                                color="info"
                                variant="contained"
                                onClick={EnviaDatos}
                                style={{ float: "right" }}
                                size="large"
                                >
                                    { Agendado ? "Guardar" : "Enviar" }
                            </Button>
                        </Grid>
                        </>
                    ) : (
                        <>
                        <Grid item xs={12} sm={12} xl={12}>
                            <Button
                                color="info"
                                variant="contained"
                                onClick={EnviaDatos}
                                style={{ float: "right" }}
                                size="large"
                                >
                                    { Agendado ? "Guardar" : "Enviar" }
                            </Button>
                        </Grid>
                        </>
                    )
                }
                
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={DialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Registro exitoso"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {`Referencia: ${IdCaptura}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={DialogClose}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogError}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialogError(false)}
                aria-describedby="AlertReferencia"
            >
                <DialogTitle>{"Folio fuera de vigencia"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="AlertReferencia">
                        {`Referencia: ${Folio}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogError(false); setIdCaptura(""); }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogEnvio}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialogEnvio(false)}
                aria-describedby="AlertMensaje"
            >
                <DialogTitle>{"Envio de mensaje"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="AlertMensaje">
                        { TextoEnvio }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogEnvio(false); }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={TokenOpen}
                onClose={() => setTokenOpen(false)}
            >
                <DialogTitle>Validar token</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ingresa el token proporcionado por tu lider
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="token"
                        name="token"
                        label="Token"
                        fullWidth
                        variant="standard"
                        value={Token} 
                        onChange={(event) => setToken(event.target.value)} 
                        inputProps={{ maxlength: 6 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTokenOpen(false)}>Cancelar</Button>
                    <Button onClick={ValidarToken}>Validar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Captura;