import React, { useEffect, forwardRef, useState } from 'react';

import Grid from "@mui/material/Grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import moment from "moment";
import "moment/locale/es";

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'Folio', label: 'Folio', minWidth: 100 },
    { id: 'Nombre', label: 'Nombre', minWidth: 100 },
    { id: 'Celular', label: 'Celular', minWidth: 100 },
    { id: 'Correo', label: 'Correo', minWidth: 100 },
    { id: 'NombreCapturista', label: 'Capturista', minWidth: 100 }
];

function Captura(){
    const [capturas, setCapturas] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);
    const [openDialogError, setOpenDialogError] = useState(false);
    const [openDialogEnvio, setOpenDialogEnvio] = useState(false);

    const [IdCaptura, setIdCaptura] = useState("");
    const [Folio, setFolio] = useState("");

    const [TextoEnvio, setTextoEnvio] = useState('');

    const [tipo, setTipo] = useState('referencia');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userPortafolios")).IdUsuario;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const selectRow = (e, row) => {
        e.stopPropagation(); 
        e.preventDefault();

        let elemento = capturas.map(element => {
            if(element.IdCaptura === row.IdCaptura)
            {
                element.select = true;
                return element;
            }
            else
            {
                element.select = false;
                return element;
            }
        });

        setCapturas(elemento);
        limpiaValores();

        let cap = elemento.find(item => item.IdCaptura === row.IdCaptura);

        let now = moment(Date.now());
        now.set({h: 0, m: 0, s: 0});
        let Sanitizacion = moment(cap.FechaSanitizacion, "DD-MM-YYYY").set({hour: 23, minute: 59, second: 59, millisecond: 0}).toDate();

        if(moment(now.toDate()).isAfter(moment(Sanitizacion))){
            setOpenDialogError(true);
            setFolio(cap.Folio);
        } else {
            setIdCaptura(cap.IdCaptura);
            setFolio(cap.Folio);
        }
    };

    const tipoNumero = (valor) => {
        let num = 0;

        switch(valor)
        {
            case "referencia":
                num = 1;
                break;
            case "telefono":
                num = 2;
                break
            case "agendada":
                num = 3;
                break
        }

        return num;
    };

    const buscar = (event) => {
        event.preventDefault();
        setLoad(true);

        limpiaValores();

        let busqueda = document.getElementById("busqueda").value;

        if(busqueda !== "" || tipo === "agendada"){
            Login()
            .then(result => {
                if(result !== undefined){
                    let params = {
                        Indicador: busqueda,
                        Tipo: tipoNumero(tipo),
                        Capturista: getUser()
                    };
    
                    ObtieneCapturas(result.token, params)
                    .then(result => {
                        let capturas = result.capturas.map((element) => {
                            element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaSanitizacion = element.FechaSanitizacion === "" ? "" : moment(moment(element.FechaSanitizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            return element;
                        });
                        setCapturas(capturas);
                        setLoad(false);
    
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes agregar un elemento a buscar", "error");
            setLoad(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneCapturas = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ObtieneCapturas",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const GeneraToken = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Mensajes/GeneraToken",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const SolicitarToken = () => {
        if(IdCaptura !== ""){
            setLoad(true);

            Login()
            .then(result => {
                let info = {
                    TokenAdmin: true,
                    IdCaptura,
                    RespuestaMensaje: "Si"
                };

                GeneraToken(result.token, info)
                .then(result => {
                    setTextoEnvio("Proporcionar el siguiente token al ejecutivo: " + result.result.Token);
                    setOpenDialogEnvio(true);
                    setLoad(false);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            setTextoEnvio("Debes seleccionar un cliente");
            setOpenDialogEnvio(true);
        }
    };

    const limpiaValores = () => {
        setIdCaptura("");
        setFolio("");
    };

    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userPortafolios')).IdUsuario;
    };

    useEffect(() => {
        Login()
        .then(result => {
            if(result !== undefined){
                let token = result.token;

                UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
                .then(result => {
                    if(result.activo === false){
                        localStorage.removeItem("userPortafolios");
                        return <Redirect to='/admin'/>;
                    }
                });
            } else {
                sendSnackBar("Error de conexión", "error");
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6} xl={6}>
                    <Paper
                        style={{ marginBottom: "30px" }}
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            id="busqueda"
                        />
                        <IconButton onClick={buscar} sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                            <FormControlLabel
                                checked={tipo === 'referencia'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="referencia" 
                                control={<Radio />} 
                                label="Referencia" />
                            <FormControlLabel
                                checked={tipo === 'telefono'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="telefono" 
                                control={<Radio />} 
                                label="Teléfono" />
                            <FormControlLabel
                                checked={tipo === 'agendada'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="agendada" 
                                control={<Radio />} 
                                label="Agendada" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { capturas.length > 0 && 
                    (
                        <Grid item xs={12} sm={12} xl={12} style={{ marginBottom: "30px" }}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {capturas.map((row) => {
                                            return (
                                                <TableRow onClick={(e) => selectRow(e, row)} selected={row.select} hover={true} tabIndex={-1} key={capturas.IdCaptura}>
                                                    {columns.map((column) => {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                { column.id === "Nombres" ? 
                                                                    row["Nombres"]
                                                                    : 
                                                                    row[column.id]
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={capturas.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
            <Grid container
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={3} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={SolicitarToken}
                        style={{ float: "right" }}
                        size="large"
                        >
                            {"Genera token"}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialogError}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialogError(false)}
                aria-describedby="AlertReferencia"
            >
                <DialogTitle>{"Folio fuera de vigencia"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="AlertReferencia">
                        {`Referencia: ${Folio}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogError(false); setIdCaptura(""); }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogEnvio}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialogEnvio(false)}
                aria-describedby="AlertMensaje"
            >
                <DialogTitle>{"Token"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="AlertMensaje">
                        { TextoEnvio }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogEnvio(false); }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Captura;